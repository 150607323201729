import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Seen.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import SeenPng from "../../../../res/seen/portrait.png"
import SeenPng2 from "../../../../res/Photos site/Seen/Graff Seen.jpg"
import SeenPng3 from "../../../../res/Photos site/Seen/Seen atelier.jpg"
import SeenPng4 from "../../../../res/Photos site/Seen/SEEN_1 - NY Graffiti Louisiana MOMA DK 1984 BD.png"
import SeenPng5 from "../../../../res/Photos site/Seen/SEEN_2 - NY Graffiti Louisiana MOMA DK 1984 BD.png"
import SeenPng6 from "../../../../res/Photos site/Seen/Seen-expertisez.jpg"
import SeenVid from "../../../../res/Photos site/Seen/lee crash seen.mp4"
import Button from "./../../../../components/Button";
import PastPresent1 from "../../../../res/seen/past-present-1.jpg"
import PastPresent2 from "../../../../res/seen/past-present-2.jpg"
import PastPresent3 from "../../../../res/seen/past-present-3.jpg"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "seen",
  name: "Seen",
  description: "SEEN, de son vrai nom Richard Mirando, est né en 1961 dans le Bronx où il a grandi. SEEN, également connu sous le nom de Richard \"Richie\" Mirando, a commencé à taguer des trains à l'âge de onze ans dans la gare de triage près de chez lui à New York. \"À partir de là, je n'ai jamais arrêté\", dit-il. Le mouvement graffiti est à cette époque en pleine émergence. Richard est captivé par les rames de métro peintes qui roulent le long de la ligne 6 ou qui stationnent dans le dépôt de Lexington Avenue, où le garage de son oncle est adossé. Il pénètre dans le dépôt un samedi après-midi de 1973 et réalise sa première « pièce ». Il choisit le pseudo « Seen ». Il va jusqu’à peindre des whole cars qui marquent l’esprit des New-Yorkais. En 1981, il prend place aux côtés d'Andy Warhol, Keith Haring et Basquiat lors de l’exposition New York, New Wave au PS1 de New York. En 1982, le film documentaire Style Wars de Tony Silver et Henry Chalfant en font une icône du mouvement dans le reste du monde.",
  pdp: SeenPng,
  alt_pdp: "Photo de profil de Seen.",
  photos: [
    { src: PastPresent1, name: "Seen" },
    { src: PastPresent2, name: "Seen" },
    { src: PastPresent3, name: "Seen" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: []
};

const Seen = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
     <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>SEEN <h2 style={{paddingLeft: "16px"}}>(Richard Mirando - Américain)</h2></h1>
          <h3 className={NameWrapper} >Né 1961</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- Super Seen, Galerie Géraldine Zberro, Paris, France</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- Post no bills, Opera Gallery, Hong Kong</p>
          <p>- Les Super-Héros de Seen, Opera Gallery, Paris, France</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Rétrospective Seen, Opera Gallery, Paris, France</p>
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- PleaSE ENjoy, Magda Danysz Gallery, Paris, France</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- The 1st SEEN Pop-Up Show, Lyon, France</p>
          <p>- Kleerup Gallery, Stockholm, Sweden 2007</p>
          <p>- Seen City, Paris, France 2006</p>
          <p>- Stolen Space Gallery, London, UK</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- Rocket World Gallery, San Francisco, CA</p>
          <p>- Outside Institute Space, London, UK</p>
          <p>- Prosper Gallery, Tokyo, Japan</p>
          <p>- McCaigwelles Gallery, New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- Toy Tokyo Gallery New York, NY</p>
          <p>- STIP Gallery, Amsterdam, The Netherlands</p>
          <p>- Stussy SF Gallery, Tokyo, Japan</p>
          <p>- Concrete Vibes Gallery, New York, NY</p>
          <p>- Marco Art Gallery, New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>2001</p>
          <p>- Twenty-four Gallery, Vancouver, Canada</p>
          <p>- Bob’s Gallery, New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>1995</p>
          <p>- Clayton Gallery, New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- Yaki Kornblit Gallery, Amsterdam, The Netherlands</p>
          <p>- Suntory Company, Tokyo, Japan</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- Yaki Kornblit Gallery, Amsterdam, The Netherlands</p>
          <p>- Stellweg-Seguy Gallery, New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>1982</p>
          <p>- Yaki Kornblit Gallery, Amsterdam, The Netherlands</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Conquête urbaine, Musée des Beaux-Arts, Calais</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- From the streets, Centre sportif La Montgolfière, Paris, France</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- Maquis-art Hall Of Fame, Musée du graffiti, L’Aérosol, Paris, France</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Playboy Redux Andy Warhol Museum, Pittsburgh, Etats-Unis</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- TAG, Grand Palais, Paris, France</p>
          <p>- Nes dans la rue, Cartier Fondation pour l’art contemporain, Paris, France</p>
          <p>- Urbain 1er, Galerie Leadouze, Paris, France</p>
          <p>- TAG, Palais de Tokyo, Paris, France</p>
          <p>- Playboy Redux, Andy Warhol Museum, Pittsburgh, Pennsylvanie</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- That 70s Show, PowerHouse Arena, New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p></p>
          <br />
          <p style={{fontWeight: "bold"}}>1991</p>
          <p>- Graffiti Art, Musée national des monuments</p>
          <br />
          <p style={{fontWeight: "bold"}}>1984</p>
          <p>- Graffiti, Louisiana Museum of Modern Art, Humlebaek, Danmark</p>
          <br />
          <p style={{fontWeight: "bold"}}>1980</p>
          <p id="end">- New Wave, MoMA P.S.1, Long Island City, USA</p>
          </div>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default Seen;