// extracted by mini-css-extract-plugin
export var ArtistDescription = "Seen-module--ArtistDescription--CDTJ7";
export var ArtistInfos = "Seen-module--ArtistInfos--W9Q0j";
export var ButtonWrapper = "Seen-module--ButtonWrapper --Y1E1s";
export var CardWrapper = "Seen-module--CardWrapper--BRw3J";
export var CarrouselWrapper2 = "Seen-module--CarrouselWrapper2--oIn-+";
export var Citations = "Seen-module--Citations--RgWzK";
export var DescriptionWrapper = "Seen-module--DescriptionWrapper--9t4oE";
export var ImageWrapper = "Seen-module--ImageWrapper--j9nm6";
export var LinkWrapper = "Seen-module--LinkWrapper--bss9+";
export var MobileProtrait = "Seen-module--MobileProtrait--ldUtd";
export var More = "Seen-module--More--Fx8hq";
export var MoreButton = "Seen-module--MoreButton--Y21P5";
export var NameWrapper = "Seen-module--NameWrapper--O+f1c";
export var PdpWrapper = "Seen-module--PdpWrapper--ESOrl";
export var PhotosWrapper = "Seen-module--PhotosWrapper--AjlpV";
export var ProfilWrapper = "Seen-module--ProfilWrapper--LXncQ";
export var TitleWrapper = "Seen-module--TitleWrapper--xB49e";
export var Wrapper = "Seen-module--Wrapper--SzQyX";